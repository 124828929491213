import $ from 'jquery';
import 'slick-carousel';


const activationSlide = () => {

  $("#slide").on("init", (event, slick) => {
    $(this).find(".slick-list").css("transform", "none");
  })

  $("#slide").slick({
    dots: true,
    arrows: false,
    autoplay: true,
    fade: true,
  });

}


;(function(){
  const slide = document.getElementById('slide')


  if (slide ){
    activationSlide()
  }
  

})();