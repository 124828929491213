import $ from 'jquery';

import './components/slide.js'
import './components/cards.js'
import './components/menu.js'
import './components/video.js'
import './components/scrollDown'

const openMenuToggle = () => {
  $('#menu-btn').on('click', (evt) => {
    const _this = evt.target

    $(_this).parent().toggleClass('active')
    $('body').toggleClass('menu-is-opened');
  })
}

const animationOnScrollDown = () => {
  const headerHeight = $('.header').innerHeight()
  const scrollValueY = window.pageYOffset
  const body = $('body')

  
  if( scrollValueY >= headerHeight ){

    body.addClass('scrolldown')
    body.removeClass('scrollup')

  } else if (scrollValueY < headerHeight && scrollValueY > 0) {    
    body.addClass('scrollup')
    // body.removeClass('scrolldown')

  } else {
    body.removeClass('scrolldown')
    body.removeClass('scrollup')
  }
}

;(function(){

  openMenuToggle()

  window.addEventListener('scroll', () => {
    animationOnScrollDown()
  })

  const revealConfig = {
    delay: 500,
    distance: '50%',
    origin: 'bottom',
    duration: 800,
  }

  ScrollReveal().reveal('.time__item', revealConfig);
  ScrollReveal().reveal('.time__item__description', {delay: 800})

  $('.cards__item').each((idx, card) => {

    const revealConfigCards = {
      delay: 500 + (idx * 100),
      distance: '50%',
      origin: 'bottom',
      duration: 1200,
    }

    ScrollReveal().reveal(card, revealConfigCards)
  })

})();