import $ from 'jquery';

const getModule = (event) => {
	const _this = event.currentTarget
	const moduleSelected = $(_this).attr('href').replace('#', '')
	const moduleNav = $(`[data-module=${moduleSelected}]`)
	const headerHeight = $('.header').innerHeight()

	$("html, body").animate({
		scrollTop: moduleNav.offset().top - headerHeight
	}, 500)
}

const activeModuleOnMenu = () => {

	const headerHeight = $('.header').innerHeight()

	const module2 = $('[data-module="sobre-nos"]').offset().top - headerHeight
	const module3 = $('[data-module="o-que-fazemos"]').offset().top - headerHeight
	const module4 = $('[data-module="manifesto"]').offset().top - headerHeight
	const module5 = $('[data-module="como-fazemos"]').offset().top - headerHeight
	const module6 = $('[data-module="contato"]').offset().top - headerHeight

	const anchor2 = $('#sobre-nos')
	const anchor3 = $('#o-que-fazemos')
	const anchor4 = $('#manifesto')
	const anchor5 = $('#como-fazemos')
	const anchor6 = $('#contato')

	const windowScrollTop = document.documentElement.scrollTop + headerHeight

	const lastModuleHeight = $('[data-module="como-fazemos"]').innerHeight()
	const moduleOnBottom = Math.round(module5) + lastModuleHeight - Math.round(lastModuleHeight / 3)

	if (windowScrollTop <= module2){
		removeActiveAnchor()
	} else if (windowScrollTop <= module3){

		removeActiveAnchor()
		anchor2.addClass('active');
	} else if (windowScrollTop <= module4) {

		removeActiveAnchor()
		anchor3.addClass('active');
	} else if (windowScrollTop <= module5) {

		removeActiveAnchor()
		anchor4.addClass('active');
	} else if (windowScrollTop <= module6 && windowScrollTop <= moduleOnBottom) {

		removeActiveAnchor()
		anchor5.addClass('active');
	} else if (windowScrollTop > moduleOnBottom) {
		

		removeActiveAnchor()
		anchor6.addClass('active');
	}

}

const removeActiveAnchor = () => {
	$('.menu a').removeClass('active');
}

const closeMenu = () => {
	$('body').removeClass('menu-is-opened')
	$('.menu__btn').removeClass('active');
}

;(function(){

	$('.menu a').on('click', (evt) => {
		evt.preventDefault()
		
		closeMenu()
		getModule(evt)
	})

	activeModuleOnMenu()

	window.addEventListener('scroll', activeModuleOnMenu)
})();