import $ from 'jquery';

;(function(){

  const module = 'div[data-module]'

	$('.scroll-down button').on('click', (evt) => {
		const _this = evt.currentTarget

		const nextModule = $(_this).parents(module).next(module);

		const headerHeight = $('.header').innerHeight()

		$("html, body").animate({
			scrollTop: nextModule.offset().top - headerHeight
		}, 1200)
	})

})();