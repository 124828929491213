import $ from 'jquery';

const video = document.getElementById('manifest-video')

const closeModal = () => {
	$('body').removeClass('video-is-opened');
	$('body').addClass('video-exit')

	setTimeout(() => {
		$('body').removeClass('video-exit')

	}, 1000)

	video.pause()
}

const openModal = () => {
	$('body').addClass('video-is-opened');

	video.play()
}

; (function () {
	$('#modal-close').on('click', () => {
		closeModal()
	})

	$('#modal-open').on('click', () => {
		openModal()
	})

	$('.hero__button').on('click', () => {
		$("html, body").animate({
			scrollTop: $('[data-module="manifesto"]').offset().top - $('.header').innerHeight()
		}, 1200)
	})

	$('.modal').on('click', (evt) => {
		const modal = $('.modal')
		const _this = evt.target


		if ($(_this).is(modal)) {

			closeModal()
		}

	})
	
})();