import $ from 'jquery';

import 'jquery-ui/ui/widgets/sortable'
import 'jquery-ui/ui/widgets/draggable'
import 'jquery-ui/ui/disable-selection'
import '../libs/jquery-ui-touch-punch.js'

const cards = $('#cards');

const bindEvents = () => {
	$('#cards li').on('click', (evt) => {
		const _this = evt.currentTarget
		const isFlipped = _this.dataset.flip
		
		_this.dataset.flip = isFlipped === 'false' ? true : false

		$('#cards li').find('.cards__inner').css('transform', '')

		// console.log(isFlipped, !isFlipped)
	})
}

const enableDraggable = () => {
	cards.sortable({
		placeholder: "ui-state-highlight"
	});
	cards.disableSelection();
}

const hoverAnimation = (event) => {
	const _this = event.currentTarget

	const elemX = _this.offsetLeft
	
	const elemW = _this.offsetWidth
	const elemTotalX = elemX + elemW
	const elemHalfX = elemTotalX - (elemW / 2)
	
	
	_this.addEventListener('mousemove', (e) => {
		if (_this.dataset.flip === 'true'){
			$(_this).find('.cards__inner').css('transform', '')
			return 
		}

		const eventX = e.screenX
		let move 

		if (eventX <= elemHalfX){
			move = -10
		} else {
			move = 30
		}
		
		// console.log('hover', elemW, elemTotalX, elemHalfX, elemThirdX)
		
		$(_this).find('.cards__inner').css('transform', `rotateY(${move}deg)`)
	})
	

}


;(function () {

	if (cards){
		bindEvents()

		enableDraggable()
		
		$('#cards li').on('mouseover',  hoverAnimation)
		$('#cards li').on('mouseleave', () => $('#cards li').find('.cards__inner').css('transform', ''))
	}

})();